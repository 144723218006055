import React from 'react';
import Wireframes from '../../../images/kaus-carousel-5.jpg';

const Component5 = () => {
  return (
    <div>
      <img src={Wireframes} alt="Kaus Wireframes" />
    </div>
  )
}

export default Component5;