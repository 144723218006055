import React from "react";
import styles from "./AboutBio.module.css";

const AboutBio = () => {
  return (
    <section className={styles.bio}>
      <p>
        Born & raised 40-minutes north of New Orleans, it was inevitable that I
        pursue music. I earned a Bachelor’s in Music Business & Violin
        Performance, & launched into a self-made career as a freelance musician
        for over 8 years.
      </p>

      <p>
        <b>
          My passions have always been understanding and connecting with people
          so I can show them a good time.
        </b>{" "}
        But after nearly a decade of late night shows, it was time to find a
        different medium.
      </p>

      <p>
        UX Design not only allows me to pursue my passion for branding, telling
        a story visually in seconds, but more importantly, it’s creating great
        experiences for people. I realized then that I’d already been exercising
        design thinking for years: Carefully crafting experiences for the
        audience at hand with just the right mix of logistics & aesthetics, plus
        a heavy-handed sprinkle of dazzle & delight.
      </p>

      <p>
        My friends used to say “little face, big voice” in reference to my
        singing voice. What a great slogan, right? I think it translates to an
        even better philosophy.{" "}
        <b>
          I apply my design skills to “little faces,” i.e. small businesses,
          nonprofits, creatives, and help them curate their “big voice.”
        </b>
      </p>

      <p>
        Are you looking for an eager beaver with a fresh perspective to join
        your team or take on your project? Let me contribute my{" "}
        <b>enthusiasm for research, excellence in synthesizing results,</b> and{" "}
        <b>engaging execution of ideas</b> to your team! Plus, I’ll throw in my
        alliteration skills for $0.99.
      </p>

      <p>
        I look forward to working together with you as we solve the world’s
        problems, big and small. Let’s talk!
      </p>
    </section>
  );
};

export default AboutBio;
